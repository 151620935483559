export const merge = (old,updated) => {
  var o = {};

  old.forEach(function(v) {
    o[v._id] = v;
  })

  updated.forEach(function(v) {
    o[v._id] = v;
  })

  var r = [];

  for(var p in o) {
    if(o.hasOwnProperty(p))
      r.push(o[p]);
  }

  return r;
}
