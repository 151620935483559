import React from 'react';

export const Logo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98 30" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-116 -3038) translate(1 3038) translate(115)"
      >
        <path
          fill={props.color || '#000000'}
          d="M23.975 29.19h-5.687V16.128a39.408 39.408 0 01-6.3 1.5c-2.11.32-4.223.529-6.342.628V29.19H0V0h5.646v12.904c2.076-.031 4.19-.21 6.341-.535a33.392 33.392 0 006.3-1.593V0h5.688v29.19zm4.427-5.517c0-3.623 2.659-5.64 6.014-6.134l4.95-.742c1.146-.164 1.514-.74 1.514-1.44 0-1.441-1.105-2.635-3.396-2.635-2.373 0-3.682 1.523-3.846 3.293l-4.827-1.029c.327-3.17 3.232-6.67 8.632-6.67 6.383 0 8.756 3.623 8.756 7.7v9.962c0 1.07.122 2.512.245 3.212h-4.991c-.123-.536-.205-1.647-.205-2.43-1.023 1.606-2.946 3.006-5.932 3.006-4.296 0-6.914-2.923-6.914-6.093zm8.06 2.017c2.29 0 4.418-1.111 4.418-4.693v-.906l-4.541.7c-1.391.206-2.496.988-2.496 2.553 0 1.193.86 2.346 2.618 2.346zm19.966-8.151v11.65h-5.441V9.832c.786-.092 1.653-.249 2.6-.47a27.73 27.73 0 002.678-.762v2.846c1.227-2.1 3.64-3.046 5.81-3.046 4.99 0 7.282 3.582 7.282 8.028V29.19h-5.442V17.374c0-2.265-1.104-4.035-3.723-4.035-2.373 0-3.764 1.853-3.764 4.2zm16.25 6.01l4.665-1.029c.122 1.606 1.309 3.088 3.682 3.088 1.8 0 2.66-.947 2.66-2.017 0-.906-.614-1.647-2.17-1.977l-2.658-.617c-3.887-.865-5.646-3.211-5.646-6.052 0-3.623 3.19-6.629 7.528-6.629 5.727 0 7.65 3.665 7.896 5.847l-4.542 1.029c-.163-1.194-1.022-2.717-3.314-2.717-1.432 0-2.577.864-2.577 2.017 0 .988.736 1.606 1.841 1.811l2.864.618c3.968.823 5.973 3.252 5.973 6.217 0 3.293-2.536 6.67-7.814 6.67-6.055 0-8.142-3.953-8.387-6.259z"
        />
        <ellipse
          cx="95.146"
          cy="26.809"
          fill="#0026DD"
          fillRule="nonzero"
          rx="2.854"
          ry="2.872"
        />
      </g>
    </svg>
  );

export const User = props => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <defs>
        <circle id="a" cx="17" cy="17" r="17" />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#F6F8F9" xlinkHref="#a" />
        <path
          fill="#9DA0A9"
          d="M24.095 20C27.06 21.537 29 24.083 29 26.964c0 4.695-5.149 8.5-11.5 8.5S6 31.66 6 26.964c0-2.653 1.645-5.022 4.222-6.581A8.973 8.973 0 0017 23.464a8.983 8.983 0 007.023-3.37z"
          mask="url(#b)"
          opacity="0.318"
        />
        <circle cx="17" cy="14" r="7" fill="#9DA0A9" opacity="0.318" />
      </g>
    </svg>
  );

  export const Close = props => (
    <svg width="27px" height="27px" viewBox="0 0 27 27" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g transform="translate(13.500000, 13.500000) rotate(-315.000000) translate(-13.500000, -13.500000) translate(-3.000000, -3.000000)" stroke="#979797" strokeWidth="3">
          <path d="M16.5,0.5 L16.5,32.5" id="Line-2"></path>
          <path d="M16.5,0.5 L16.5,32.5" id="Line-2-Copy" transform="translate(16.500000, 16.500000) rotate(90.000000) translate(-16.500000, -16.500000) "></path>
        </g>
      </g>
    </svg>
  );

  export const Success = props => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="68"
      viewBox="0 0 68 68"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-591 -639) translate(591 639)"
      >
        <circle cx="34" cy="34" r="34" fill="#00CD84" opacity="0.571"></circle>
        <circle cx="34" cy="34" r="31" fill="#00CD84" opacity="0.734"></circle>
        <circle cx="34" cy="34" r="26" fill="#00CD84"></circle>
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M22 34.5987945L28.3877279 44 50 24"
        ></path>
      </g>
    </svg>
  );
