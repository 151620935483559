import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Onboarding from './pages/Onboarding/Onboarding';
import Property from './pages/Property';
import Billing from './pages/Billing';
import Messages from './pages/Messages';
import Management from './pages/Management';
import CreateProperty from './pages/CreateProperty';
import RequireAuth from './RequireAuth';
import UnRequireAuth from './UnRequireAuth';
import RequireAdmin from './RequireAdmin';
import CookieNotice from './parts/CookieNotice';
import '../../css/style.scss';

const App = props => {
  const { location } = props;
  return <div>
      <Switch location={location}>
        <Route path="/login" component={UnRequireAuth(Login)} />
        <Route path="/signup" component={UnRequireAuth(SignUp)} />
        <Route path="/forgot-password" component={UnRequireAuth(ForgotPassword)} />
        <Route path="/reset-password/:token/:email" component={UnRequireAuth(ResetPassword)} />
        <Route path="/willkommen/:token/:email" component={UnRequireAuth(Onboarding)} />
        <Route exact path="/properties/new" component={RequireAuth(CreateProperty)} />
        <Route path="/properties/:id" component={RequireAuth(Property)} />
        <Route path="/billing" component={RequireAuth(Billing)} />
        <Route path="/messages" component={RequireAuth(Messages)} />
        <Route path="/management" component={RequireAdmin(Management)} />
        <Route path="/" component={RequireAuth(Dashboard)} />
      </Switch>
      <CookieNotice />
    </div>;
}

export default withRouter(App);
