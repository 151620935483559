import axios from 'axios';
import { tokenHeader } from '../authentication';

export function fetchUsers(){
	return dispatch => {
		dispatch({type: 'FETCH_USERS_REQUEST'});
		axios.get(`${process.env.REACT_APP_API_BASE}/admin/users?sortBy=createdAt:desc`, tokenHeader())
		.then(response => {
      dispatch({
				type: 'FETCH_USERS_SUCCESS',
				users: response.data
			});
		})
		.catch(error => {
			dispatch({type: 'FETCH_USERS_ERROR'});
		});
	}
}

export function fetchUser(userId){
	return dispatch => {
		dispatch({type: 'FETCH_USER_REQUEST'});
		axios.get(`${process.env.REACT_APP_API_BASE}/admin/users/${userId}`, tokenHeader())
		.then(response => {
      dispatch({
				type: 'FETCH_USER_SUCCESS',
				user: response.data
			});
		})
		.catch(error => {
			dispatch({type: 'FETCH_USER_ERROR'});
		});
	}
}

export function onboardUser(userId){
	return dispatch => {
		dispatch({type: 'ONBOBARD_USER_REQUEST'});
		axios.patch(`${process.env.REACT_APP_API_BASE}/admin/users/${userId}/onboard`, tokenHeader())
		.then(response => {
      dispatch({
				type: 'ONBOBARD_USER_SUCCESS',
				user: response.data
			});
		})
		.catch(error => {
			dispatch({type: 'ONBOBARD_USER_ERROR'});
		});
	}
}
