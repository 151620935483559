import React from 'react';
import { connect } from 'react-redux';
import AuthenticationPage from '../layouts/AuthenticationPage';
import Select from '../parts/Select';
import Input from '../parts/Input';
import Button from '../parts/Button';
import * as actions from '../../actions';

class SignUp extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      salutation: 'Herr',
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    }
  }

  componentDidUpdate(prevProps){
    if(!prevProps.error && this.props.error){
      this.setState({
        email: '',
        password: ''
      })
    }
  }

  _handleSignUp = event => {
    event.preventDefault();
    this.props.signUp({
      user: this.state,
      autoLogin: true
    });
  }

  _handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render(){
    console.log(this.state);

    return (
      <AuthenticationPage>
        <div className="login">
          <div>
            <p>Verwalte Services für dein Objekt einfach und unkompliziert jetzt über unsere Plattform.</p>
            <form onSubmit={e => this._handleSignUp(e)}>
              <Select name="salutation" onChange={this._handleChange} value={this.state.salutation}>
                <option value="Herr">Herr</option>
                <option value="Frau">Frau</option>
              </Select>
              <br />
              <Input required type="text" name="firstName" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Vorname" value={this.state.firstName}/>
              <Input required type="text" name="lastName" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Nachname" value={this.state.lastName}/>
              <Input required type="text" name="email" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="E-Mail" value={this.state.email}/>
              <Input required type="password" name="password" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Password" value={this.state.password}/>
              <br />
              {this.props.error &&
                <div className="error-message">{this.props.error}</div>
              }
              <Button type="primary" color="green" disabled={this.state.email === '' || this.state.password === ''}>Konto erstellen</Button>
            </form>
          </div>
          <Button to="/login" type="naked">oder anmelden</Button>
        </div>
      </AuthenticationPage>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.authentication.error
  };
}

export default connect(mapStateToProps, actions)(SignUp);
