import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Logo, User } from '../parts/Svg';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import * as actions from '../../actions';

const Header = props => {
  const [ menuVisible, showMenu ] = useState(false);

  return <div className="header">
    <div className="inner inner--flex">
      <div className="header__menu-toggle" onClick={() => showMenu(true)}>
        <span />
        <span />
        <span />
      </div>
      <Link className="header__logo__link" to="/">
        <Logo className="header__logo" color="#000000"/>
      </Link>

      <ul className={classNames('header__menu', {'visible': menuVisible})}>
        <li><NavLink activeClassName="active" exact to="/">Dashboard</NavLink></li>
        <li><NavLink activeClassName="active" exact to="/billing">Rechnungen</NavLink></li>
        <li><NavLink activeClassName="active" exact to="/messages">Nachrichten</NavLink></li>
        {props.user.role === 'admin' &&
          <li><NavLink activeClassName="active" to="/management">Management</NavLink></li>
        }
      </ul>
      <div className={classNames('header__cover', {'visible': menuVisible})} onClick={() => showMenu(false)}/>

      <div className="header__actions">
        <div className="support">
          Hotline <a href="tel:+4962172840411">+49 0621 728 40 411</a>
        </div>
        {props.user &&
          <div className="logout" onClick={() => props.signoutUser()}>
            <User />
            <span className="label">
              {props.user.firstName} {props.user.lastName} abmelden
            </span>
          </div>
        }
      </div>
    </div>
  </div>
}

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
  }
}

export default connect(mapStateToProps, actions)(Header);
