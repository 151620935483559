import React from 'react';
import { connect } from 'react-redux';
import AuthenticationPage from '../layouts/AuthenticationPage';
import Input from '../parts/Input';
import Button from '../parts/Button';

import * as actions from '../../actions';

class ForgotPassword extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      email: '',
    }
  }

  componentDidUpdate(prevProps){
    if(!prevProps.error && this.props.error){

    }
  }

  _handleSubmit = event => {
    event.preventDefault();
    // TODO: implement
    console.log('handle submit');
    this.props.sendPasswordResetMail(this.state.email);
  }

  _handleChange = event => {
    console.log(event);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render(){

    return (
      <AuthenticationPage>
        <form className="login" onSubmit={e => this._handleSubmit(e)}>
          {this.props.passwordReset === 'requested'
          ?
            <p>Bitte überprüfe deine E-Mails. Wir haben dir einen Link zum Zurücksetzen deines Passworts geschickt.</p>
          :
            <React.Fragment>
            <p>Du weißt nicht mehr, wie du dich anmelden musst? Kein Problem, dann setze hier einfach dein Passwort zurück.</p>
            <br />
              <Input type="email" name="email" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Deine E-Mail-Adresse" value={this.state.email}/>
              <br />
              {this.props.error &&
                <div className="error-message">{this.props.error}</div>
              }
              <Button type="primary" disabled={this.state.email === '' || this.state.password === ''}>Neues Passwort anfordern</Button>
            </React.Fragment>
          }
        </form>
        <Button to="/" type="naked">zurück zur Anmeldung</Button>
      </AuthenticationPage>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.authentication.error,
    passwordReset: state.authentication.passwordReset
  };
}

export default connect(mapStateToProps, actions)(ForgotPassword);
