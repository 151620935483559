import axios from 'axios';
import { tokenHeader } from './authentication';

export function fetchProperties(){
	return dispatch => {
		dispatch({type: 'FETCH_PROPERTIES_REQUEST'});
		axios.get(`${process.env.REACT_APP_API_BASE}/properties`, tokenHeader())
		.then(response => {
      dispatch({
				type: 'FETCH_PROPERTIES_SUCCESS',
				properties: response.data
			});
		})
		.catch(error => {
			dispatch({
				type: 'FETCH_PROPERTIES_ERROR',
				error: error.response.data.message
			});
		});
	}
}

export function createProperty(property){
	return (dispatch) => {
		dispatch({type: 'CREATE_PROPERTY_REQUEST'});
    axios.post(`${process.env.REACT_APP_API_BASE}/properties`, property, tokenHeader())
    .then(response => {
			dispatch({
				type: 'CREATE_PROPERTY_SUCCESS',
				property: response.data
			});
    })
    .catch((error) =>{
			dispatch({
        type: 'CREATE_PROPERTY_ERROR',
        error: error.response.data.message
      });
    });
  }
}

export function deleteProperty(property){
	return (dispatch) => {
		dispatch({type: 'DELETE_PROPERTY_REQUEST'});
    axios.delete(`${process.env.REACT_APP_API_BASE}/properties/${property._id}`, tokenHeader())
    .then(response => {
			dispatch({
				type: 'DELETE_PROPERTY_SUCCESS',
				property: response.data
			});
    })
    .catch((error) =>{
			dispatch({
        type: 'DELETE_PROPERTY_ERROR',
				error: error.response.data.message
			});
    });
  }
}
