import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { find, filter } from 'lodash';
import classNames from 'classnames';
import Button, { BackButton } from '../../parts/Button';
import CreateActivity from '../../modals/CreateActivity';
import { withPopups } from "react-popup-manager";
import * as actions from '../../../actions';


export const ActivityList = withPopups()(props => {
  const { activities } = props;

  const openActivityModal = () => {
    props.popupManager.open(CreateActivity, {
      title: 'Neue Aktivität anlegen',
      data:{
        property: props.property
      },
      onClose: (...params) => console.log('modal has closed with:', ...params)
    });
  }

  return (
    <div className="activity-list">
      <div className="activity-list__header">
        <h3>Aktivitäten</h3>
        {props.enableAddNew &&
          <Button onClick={() => openActivityModal()} type="primary" color="grey">
          <span className="material-icons">add</span>
          </Button>
        }
      </div>
      <div className="activity-list__body">
        {activities && activities.length > 0 ?
            <ul className="activity-items">
              {activities.map(activity => (
                <li className={classNames('activity-item', {
                  'activity-item--completed': activity.completed
                })} key={activity._id}>
                  {activity.name} ({activity.description})
                </li>
              ))}
            </ul>
          :
          <div style={{color: '#888', marginTop: '20px'}}>Derzeit gibt es keine Aktivitäten</div>
        }
      </div>
      <div className="activity-list__footer" />
    </div>
  )
});

const UserProperty = props => {
  useEffect(() => {
    const { propertyId } = props.match.params;
    props.fetchUserProperty(propertyId);

    props.fetchPropertyActivities(propertyId);
  }, [])

  useEffect(() => {
    if(props.property){
      props.fetchUser(props.property.owner);
    }
  }, [props.property]);

  if(!props.user || !props.property){
    return false;
  }

  return (
    <div className="single single--property">
      <BackButton />
      <div className="single__title">
        Kundenobjekt: {props.property.name}
      </div>
      <div className="single__body">
        <div className="row">
          <div className="col">
            <div style={{color: '#888'}}>
              Adresse: {props.property.addressStreet} <br />
              Postleitzahl: {props.property.addressPostalCode} <br />
              Ort: {props.property.addressCity}<br />
              <br />
              Telefon: {props.property.landline}<br />
              Mobil: {props.property.mobilePhone}<br />
            </div>

            <br />

            <div style={{color: '#888'}}>
              {props.user.firstName} {props.user.lastName}<br />
              {props.user.email} <br />
            </div>
          </div>
          <div className="col">
            <ActivityList property={props.property} activities={props.activities} enableAddNew/>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { propertyId } = ownProps.match.params;

  const property = find(state.admin.properties.properties, {_id: propertyId});

  let user;
  let activities;

  if(property){
    user = find(state.admin.users.users, {_id: property.owner});
    activities = filter(state.admin.activities.activities, {property:  propertyId});
  }

  return {
    property,
    user,
    activities
  }
}


export default connect(mapStateToProps, actions)(UserProperty);
