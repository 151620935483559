import React from 'react';
import { connect } from 'react-redux';

import Button from '../../parts/Button';
import Input from '../../parts/Input';

import * as actions from '../../../actions';


class Details extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      phone: false,
      password1: false,
      password2: false,
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.passwordReset !== 'reset' && this.props.passwordReset === 'reset'){
      this.props.history.push(`/login`);
    }
  }

  // TODO: improve validation logic
  _isValid = () => {
    return this.state.password1 !== false && this.state.password1.length > 5 && (this.state.password1 === this.state.password2);
  }

  _handleSubmit = event => {
    event.preventDefault();

    this.props.resetPassword({
      password: this.state.password1,
      resetToken: this.props.match.params.token,
      email: this.props.match.params.email
    });
  }

  _handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  render(){
    console.log(this.props.passwordReset, this.props.error);

    const { firstName, lastName, email } = this.props.user;
    const fullName = `${firstName} ${lastName}`;

    // TODO: improve loading logic / show loading state

    return (
      <div className="box">
        <div className="inner">
          <img className="icon" src={'/illustrations/welcome.png'} alt=""/>
          <h1>Vervollständige deine Daten</h1>

          <br />
          {this.props.user &&
            <form onSubmit={e => this._handleSubmit(e)}>
              <Input type="text" disabled name="name" defaultValue={fullName} />
              <Input type="text" disabled name="name" defaultValue={email}/>
              {/*}<Input type="tel" name="phone" onChange={this._handleChange} placeholder='Deine Telefonnummer'/>*/}
              <Input type="password" required name="password1" onChange={this._handleChange} placeholder="Dein Passwort"/>
              <Input type="password" required name="password2" onChange={this._handleChange} placeholder="Passwort wiederholen"/>
              <br />
              {this.props.error &&
                <div className="error-message">{this.props.error}</div>
              }
              <Button color="green" disabled={!this._isValid()}>Weiter</Button>
            </form>
          }
          <br />
          <br />
          <p className="small">Vervollständige deine Anmeldung in nur 2 Minuten und verwalte alle deine Immobilien auf einen Blick</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.onboarding.user,
    passwordReset: state.authentication.passwordReset,
    error: state.authentication.error
  }
}


export default connect(mapStateToProps, actions)(Details);
