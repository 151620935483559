import React from 'react';
import classNames from 'classnames';
import { Logo } from '../parts/Svg';

export default props => {
  return <div className={classNames('page page--authentication', {error: props.error})}>
    <div className="box">
      <div className="inner">
        <Logo className="logo" color="#000000"/>
        {props.children}
      </div>
    </div>
    <div className="cover" />
  </div>
}
