import React from 'react';
import { connect } from 'react-redux';
import { BackButton } from '../../parts/Button';
import * as actions from '../../../actions';
import uniqid from 'uniqid';

class CreateUser extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      salutation: "Herr",
      firstName: "Nico",
      lastName: "Elzer",
      email: `${uniqid()}@schulzmarcel.de`,
      password: "Testing123"
    }
  }

  componentDidMount(){
    //this.props.fetchUsers();
  }

  componentDidUpdate(prevProps){
    if(
      typeof prevProps.users !== 'undefined' && (prevProps.users.length < this.props.users.length) && prevProps.users !== false
    ){
      this.props.history.push(`/management/users/${this.props.users[this.props.users.length - 1]._id}`);
    }
  }

  _handleSubmit = event => {
    event.preventDefault();
    console.log(this.state);
    this.props.createUser({
      user: this.state,
      autoLogin: false
    });
  }

  _handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  render(){
    return (
      <div>
        <BackButton />
        <h2>
          Neuen Kunden anlegen
        </h2>
        <form onSubmit={e => this._handleSubmit(e)}>
          <select name="salutation" onChange={this._handleChange} required defaultValue={this.state.salutation}>
            <option disabled value="">Bitte auswählen</option>
            <option value="Herr">Herr</option>
            <option value="Frau">Frau</option>
          </select>


          <input type="text" name="firstName" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Vorname" value={this.state.firstName}/>
          <br />
          <input type="text" name="lastName" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Nachname" value={this.state.lastName}/>
          <br />
          <input type="text" name="email" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="E-Mail-Adress" value={this.state.email}/>
          <br />
          <input type="text" name="password" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Passwort" value={this.state.password}/>
          <br />

          <br />
          {this.props.error &&
            <div className="error-message">{this.props.error}</div>
          }
          <button type="submit" className="button">Kunden anlegen</button>
        </form>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { users, loading } = state.admin.users;

  return {
    loading,
    users
  }
}


export default connect(mapStateToProps, actions)(CreateUser);
