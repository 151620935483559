const activitiesState = {
  loading: false,
  activities: []
};

export default (state = activitiesState, action) => {
  switch(action.type){
    case 'FETCH_ACTIVITIES_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_ACTIVITIES_SUCCESS':
      return {
        ...state,
        activities: action.activities,
        loading: false
      };
    case 'FETCH_ACTIVITIES_ERROR':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
