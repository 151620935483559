const notificationState = {
  loading: false,
  success: false,
};

export default (state = notificationState, action) => {
  switch(action.type){
    case 'SEND_EMAIL_REQUEST':
      return {
        ...state,
        loading: true,
        success: false,
      };
    case 'SEND_EMAIL_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
      };
    case 'SEND_EMAIL_ERROR':
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
}
