import axios from 'axios';
import { signInUser } from './authentication';

export function createUser(data){
	return dispatch => {
		dispatch({type: 'CREATE_USER_REQUEST'});
		axios.post(`${process.env.REACT_APP_API_BASE}/users`, data.user)
		.then(response => {
			console.log(response);

			dispatch({
				type: 'CREATE_USER_SUCCESS',
				user: response.data.user
			});

			if(data.autoLogin){
				localStorage.setItem('hansAuthToken',response.data.token);
				dispatch(signInUser({
					email: data.user.email,
					password: data.user.password
				}));
			}
		})
		.catch((error) =>{
			dispatch({type: 'CREATE_USER_ERROR', error});
    });
	}
}

export function signUp(data){
	return dispatch => {
		dispatch({type: 'SIGNUP_REQUEST'});
		axios.post(`${process.env.REACT_APP_API_BASE}/users`, data.user)
		.then(response => {

			dispatch({
				type: 'SIGNUP_SUCCESS',
				user: response.data.user
			});

			if(data.autoLogin){
				localStorage.setItem('hansAuthToken',response.data.token);
				dispatch(signInUser({
					email: data.user.email,
					password: data.user.password
				}));
			}
		})
		.catch((error) =>{
			// TODO: change once API property renamed
			dispatch({
				type: 'SIGNUP_ERROR',
				error: error.response.data.errmsg
			});
    });
	}
}
