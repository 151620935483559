import axios from 'axios';
import { tokenHeader } from './authentication';

export function sendRatingMail(to, firstname, lastname){
	return (dispatch) => {
		dispatch({type: 'SEND_RATING_MAIL_REQUEST'});
    axios.post(`${process.env.REACT_APP_API_BASE}/admin/ratingMail/`, {to, firstname, lastname}, tokenHeader())
    .then(response => {
      console.log(response);
			if(response.status === 201){
				dispatch({type: 'SEND_RATING_MAIL_SUCCESS'});
			}else{
				dispatch({type: 'SEND_RATING_MAIL_ERROR'});
			}
    })
    .catch((error) =>{
			dispatch({type: 'SEND_RATING_MAIL_ERROR'});
    });
  }
}
