import React from 'react';

export default props => {
  return (
    <div className="field field--select">
      <select name="salutation" {...props}>
        {props.children}
      </select>
    </div>
  )
}
