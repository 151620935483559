import React, { useState } from 'react';
import classNames from 'classnames';

export default props => {
  const [ focused, setFocus ] = useState(false);
  const [ content, setContent ] = useState(props.defaultValue || '');

  const _handleChange = e => {
    setContent(e.target.value);

    if(props.onChange){
      props.onChange(e);
    }
  }

  const _handleFocus = e => {
    setFocus(true);
  }

  return (
    <div className={classNames(`field field--${props.type}`, {
      'field--focused': focused && !content,
      'field--dirty': content.length > 0,
      'field--required': props.required
    })}>
      <label>{props.placeholder}</label>
      <input
        required={props.required}
        type={props.type}
        name={props.name}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        onChange={e => _handleChange(e)}
        onFocus={e => _handleFocus(e)}
        onBlur={e => setFocus(false)}
        />
    </div>
  )
}
