// import { find } from 'lodash';

const usersState = {
  loading: false,
  success: false,
  users: []
};

export default (state = usersState, action) => {
  switch(action.type){
    // FETCH USERS
    case 'FETCH_USERS_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_USERS_SUCCESS':
      return {
        ...state,
        users: action.users,
        loading: false
      };
    case 'FETCH_USERS_ERROR':
      return {
        ...state,
        loading: false
      };

    // FETCH USER
    case 'FETCH_USER_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_USER_SUCCESS':
      return {
        ...state,
        users: [
          ...state.users,
          action.user
        ],
        loading: false
      };
    case 'FETCH_USER_ERROR':
      return {
        ...state,
        loading: false
      };

    // CREATE USER
    case 'CREATE_USER_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'CREATE_USER_SUCCESS':
      return {
        ...state,
        users: [
          ...state.users,
          action.user
        ],
        loading: false
      };
    case 'CREATE_USER_ERROR':
      return {
        ...state,
        loading: false
      };

    // ONBOARD USER
    case 'ONBOBARD_USER_REQUEST':
      return {
        ...state,
        loading: true,
        success: false,
      };
    case 'ONBOBARD_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
      };
    case 'ONBOBARD_USER_ERROR':
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
}
