import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import Button from '../../parts/Button';
import { UserStatus } from '../../parts/Tag';
import { find, filter } from 'lodash';
import SuccessModal from '../../modals/SuccessModal';
import { withPopups } from "react-popup-manager";
import PropertyList from '../../parts/PropertyList';

const User = props => {
  const [ onboarded, setOnboarded ] = useState(false);
  const [ passwordReset, setPasswordReset ] = useState(false);

  useEffect(() => {
    const { id } = props.match.params;

    props.fetchUser(id);
    // TODO: find way to fix this and remove timeout
    setTimeout(() => props.fetchUserProperties(id), 500);
  }, []);

  useEffect(() => {
    if(props.success && !props.loading){
      setOnboarded(true);
      props.popupManager.open(SuccessModal, {
        title: 'Einladung gesendet',
        body: 'Der Kunde wurde eingeladen, sein Konto zu vervollständigen und sich anzumelden.'
      });
    }

    if(props.passwordReset && !props.authenticationLoading){
      setPasswordReset(true);
      props.popupManager.open(SuccessModal, {
        title: 'Passwort zurückgesetzt',
        body: 'Der Kunde hat eine E-Mail erhalten, über die ein neues Passwort gesetzt werden kann.'
      });
    }
  }, [props.loading, props.success, props.authenticationLoading, props.passwordReset]);

  const _handleOnboardClick = () => {
    props.onboardUser(props.user._id);
  }

  const _handlePasswordResetClick = () => {
    props.sendPasswordResetMail(props.user.email);
  }

  return props.user ?
    <div className="single single--user">
      <Button type="primary" color="grey" size="small" to="/management" className="back">zurück</Button>
      <div className="single__title">
        {props.user.firstName} {props.user.lastName}
        &nbsp;
        <UserStatus>{props.user.status}</UserStatus>
      </div>

      <div className="single__actions">
        <Button to={`mailto:${props.user.email}`} type="primary" color="grey" size="small">Nachricht schreiben</Button>
        <Button disabled={onboarded || props.user.status === 'activated'} onClick={() => _handleOnboardClick()} type="primary" color="grey" size="small">Einladung {props.user.status === 'onboarded' && 'erneut'} senden</Button>
        <Button disabled={passwordReset} onClick={() => _handlePasswordResetClick()} type="primary" color="grey" size="small">Passwort zurücksetzen</Button>
        <Button disabled type="primary" color="grey" size="small">Kunden löschen</Button>
      </div>

      <div className="single__subtitle">

      </div>

      <div className="single__body">
        <div className="row">
          <div className="col">
            <div className="section">
              <div className="section__title">
                Objekte
              </div>
              <div className="section__body">
                <PropertyList properties={props.properties} user={props.user} enableAddNew />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  : null;
}

const mapStateToProps = (state, ownProps) => {
  const user = find(state.admin.users.users, {_id: ownProps.match.params.id});
  const { loading, success } = state.admin.users;
  let properties = [];

  if(user){
    properties = filter(state.admin.properties.properties, {owner: user._id});
  }

  return {
    user,
    properties,
    loading,
    success,
    authenticationLoading: state.authentication.loading,
    passwordReset: state.authentication.passwordReset
  }
}

export default connect(mapStateToProps, actions)(withPopups()(User));
