import React from 'react';
import classNames from 'classnames';

export const Tag = props => {
  return (
    <span className={classNames('tag', `tag--${props.color}`)}>
      {props.children || ''}
    </span>
  )
}

export const UserStatus = props => {
  const status = props.children;
  let label, color;

  // TODO: fix once backend fixed
  switch(status){
    case 'Created':
      label = 'angelegt';
      color = 'grey';
      break;
    case 'Invited':
    case 'onboarded':
    case 'Onboarded':
      label = 'eingeladen';
      color = 'yellow';
      break;
    case 'Activated':
    case 'activated':
      label = 'aktiv';
      color = 'green';
      break;
    default: break;
  }

  return (
    <Tag color={color}>{label}</Tag>
  )
}


export default Tag;
