import React from 'react';
import { connect } from 'react-redux';

export default (ComposedComponent) => {
  class Authentication extends React.Component {
    componentDidMount() {
      if (!this.props.authenticated || (this.props.user && this.props.user.role !== 'admin')) {
        this.props.history.push('/login');
      }
    }
    componentDidUpdate() {
      if (!this.props.authenticated || (this.props.user && this.props.user.role !== 'admin')) {
        this.props.history.push('/login');
      }
    }

    render() {
      if(this.props.authenticated && !this.props.user){
        return <div />;
      }
      return <ComposedComponent {...this.props} />
    }
  }

  const mapStateToProps = state => {
    return {
      authenticated: state.authentication.authenticated,
      user: state.authentication.user
    };
  }

  return connect(mapStateToProps)(Authentication);
}
