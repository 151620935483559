import React, { useState, useEffect } from 'react';
import Button from '../parts/Button';
import Input from '../parts/Input';
import { Close } from '../parts/Svg';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { withPopups } from "react-popup-manager";
import SuccessModal from './SuccessModal';

const CreateActivity = props => {
  const [ name, setName ] = useState();
  const [ description, setDescription ] = useState();

  const close = () => {
    props.onClose('param', 'param2', 'param3');
  }

  const _handleSubmit = () => {
    props.createPropertyActivity({
      propertyId: props.data.property._id,
      activity: {
        name,
        description
      }
    });
  }

  useEffect(() => {
    if(props.success && !props.loading){
      close();
      props.fetchPropertyActivities(props.data.property._id);
      props.popupManager.open(SuccessModal, {
        title: 'Aktivität angelegt',
        body: 'Die Aktivität zum Objekt wurde erfolgreich angelegt.'
      });
    }
  }, [props.success, props.loading]);


  return <div className="modal">
    <div className="modal__box">
      <div className="modal__box__head">
        <span>{props.title || ''}</span>
        <button onClick={() => close()} className="close">
          <Close />
        </button>
      </div>
      <div className="modal__box__body">
        <form onSubmit={() => _handleSubmit()}>
          <Input required type="text" name="name" defaultValue={name} onChange={e => setName(e.currentTarget.value)} placeholder={`Name (z.B. "Treppenhausreinigung")`} />
          <Input required type="text" name="description" defaultValue={description} onChange={e => setDescription(e.currentTarget.value)} placeholder={`Beschreibung (z.B. "Gereinigt von Kim am 11.12.2019")`} />
        </form>

      </div>
      <div className="modal__box__footer">
        <Button type="primary" size="small" onClick={() => _handleSubmit()}>Confirm</Button>
      </div>
    </div>
  </div>;
}

const mapStateToProps = (state, ownProps) => {
  const { loading, success } = state.admin.activities;

  console.log(loading, success);

  return {
    loading,
    success
  }
}

export default connect(mapStateToProps, actions)(withPopups()(CreateActivity));
