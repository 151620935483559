import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthenticationPage from '../layouts/AuthenticationPage';
import Input from '../parts/Input';
import Button from '../parts/Button';

import * as actions from '../../actions';

class Login extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      email: '',
      password: ''
    }
  }

  componentDidUpdate(prevProps){
    if(!prevProps.error && this.props.error){
      // this.setState({
      //   email: '',
      //   password: ''
      // })
    }
  }

  _handleLogin = event => {
    event.preventDefault();
    console.log('login', this.state);
    this.props.signInUser(this.state);
  }

  _handleChange = event => {
    console.log(event);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render(){

    return (
      <AuthenticationPage error={this.props.error}>
        <form className="login" onSubmit={e => this._handleLogin(e)}>
          <Input type="text" name="email" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Deine E-Mail-Adresse" value={this.state.email}/>
          <Input type="password" name="password" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Password" value={this.state.password}/>
          <Link to="/forgot-password" className="hint">Passwort vergessen?</Link>
          <br />
          {this.props.error &&
            <div className="error-message">{this.props.error}</div>
          }
          <Button type="primary" disabled={this.state.email === '' || this.state.password === ''}>Anmelden</Button>
        </form>
        <Button to="/signup" type="naked">oder Konto anlegen</Button>
      </AuthenticationPage>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.authentication.error
  };
}

export default connect(mapStateToProps, actions)(Login);
