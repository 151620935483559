import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import AppPage from '../layouts/AppPage';
import PropertyList from '../parts/PropertyList';
import * as actions from '../../actions';

const Dashboard = props => {
  useEffect(() => {
    props.fetchProperties();
    props.fetchActivities();
  }, [])

  if(!props.user){
    return false;
  }

  const { activities, properties } = props;

  return (
    <AppPage submenu={[
        {
          to: '/',
          label: 'Objekte',
          exact: true
        },
        // NOTE: hidden until there is a list of activities across all objects
        // {
        //   to: '/activity',
        //   label: 'Aktivitäten',
        // },
      ]}>

      <Switch location={props.location}>
        <Route path="/activity" render={() => (
            activities && activities.length > 0 ?
            <React.Fragment>
              <ul>
                {activities.map(activity => <li key={activity._id}>
                  {activity.name} ({activity.description})
                </li>)}
              </ul>
            </React.Fragment>
            :
            <div>Keine Aktivitäten</div>
        )} />
        <Route exact path="/" render={() => <PropertyList properties={properties} enableAddNew />} />
      </Switch>
    </AppPage>
  );
}

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    properties: state.properties.properties,
    activities: state.activities.activities,
  }
}


export default connect(mapStateToProps, actions)(Dashboard);
