import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import cookie from 'react-cookies';
import ReactGA from 'react-ga';

import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

export default props => {
  const [ dismissed, setDismissed ] = useState(cookie.load('_hans_cookie'));

  useEffect(() => {
    checkCookiePermission()
  }, []);

  const checkCookiePermission = () => {
    if(cookie.load('_hans_cookie') === 'true'){
      ReactGA.initialize('UA-138129711-2');
    	ReactGA.set({ anonymizeIp: true });
    	ReactGA.pageview('/');
    	history.listen(location => {
    		ReactGA.pageview(location.pathname);
    	});
    }
  }

  const handleCookieNoticeClick = consent => {
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 365);

    if(consent){
      cookie.save('_hans_cookie', 'true', { path: '/' , expires});
    }else{
      cookie.save('_hans_cookie', 'false', { path: '/' , expires});
    }

    checkCookiePermission();
    setDismissed(true);
  }

  return <div className={classNames('cookie-notice', {
      'cookie-notice--dismissed' : dismissed
    })}>
  <div>
    Wir verwenden Cookies um Nutzungsdaten über unsere Website zu sammeln und das Nutzererlebnis zu verbessern. Bitte wählen Sie, ob Sie damit einverstanden sind. Mehr Informationen in unserer <Link to="/datenschutz">Datenschutzerklärung</Link>.
  </div>
  <div className="actions">
    <button className="a button button--secondary" onClick={() => handleCookieNoticeClick(false)}>Keine Cookies setzen</button>
    <button className="a button button--primary" onClick={() => handleCookieNoticeClick(true)}>Einverstanden</button>
  </div>
</div>
}
