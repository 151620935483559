const propertiesState = {
  loading: false,
  error: false,
  properties: []
};

export default (state = propertiesState, action) => {
  switch(action.type){
    // FETCH PROPERTIES
    case 'FETCH_PROPERTIES_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_PROPERTIES_SUCCESS':
      return {
        ...state,
        properties: action.properties,
        loading: false
      };
    case 'FETCH_PROPERTIES_ERROR':
      return {
        ...state,
        loading: false
      };

    // CREATE PROPERTY
    case 'CREATE_PROPERTY_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'CREATE_PROPERTY_SUCCESS':
      return {
        ...state,
        properties: [
          ...state.properties,
          action.property
        ],
        loading: false
      };
    case 'CREATE_PROPERTY_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      };

    // DELETE PROPERTY
    case 'DELETE_PROPERTY_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'DELETE_PROPERTY_SUCCESS':
      return {
        ...state,
        properties: state.properties.filter(property => property._id !== action.property._id),
        loading: false
      };
    case 'DELETE_PROPERTY_ERROR':
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
