import React from 'react';
import PlaceholderPage from '../layouts/PlaceholderPage';
import Button from '../parts/Button';

const Messages = props => {
  return (
    <PlaceholderPage title="Deine Nachrichten" icon={'/illustrations/05- mail-letter-content-1.png'}>
      Falls du Fragen du einem Auftrag oder unseren Leistungen hast, erreichst du uns gern jederzeit per E-Mail oder WhatsApp.
      <br />
      <br />
      <Button to="mailto:hallo@hans-hausmeister.de">E-Mail schreiben</Button>
    </PlaceholderPage>
  );
}

export default (Messages);
