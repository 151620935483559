import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { BackButton } from '../parts/Button';
import AppPage from '../layouts/AppPage';
import { ActivityList } from './Management/UserProperty';
import * as actions from '../../actions';

const Property = props => {
  useEffect(() => {
    props.fetchProperties();
    props.fetchActivities();
  }, [])

  if(!props.property){
    return false;
  }

  return (
    <AppPage>
    <div className="single single--property">
      <BackButton />
      <div className="single__title">
        {props.property.name}
      </div>
      <div className="single__body">
        <div className="row">
          <div className="col">
            <div style={{color: '#888'}}>
              Adresse: {props.property.addressStreet} <br />
              Postleitzahl: {props.property.addressPostalCode} <br />
              Ort: {props.property.addressCity}<br />
              <br />
              <small className="info-message">
                Dein Objekt ist jetzt in unserem System angelegt. Wenn du neue Dienstleistungen für die Immobilie buchen willst, kontaktiere uns bitte unter hallo@hans-hausmeister.de oder telefonisch unter 0621 728 40 411
              </small>
            </div>
          </div>
          <div className="col">
            <ActivityList property={props.property} activities={props.activities} enableAddNew={false}/>
          </div>
        </div>
      </div>
    </div>
    </AppPage>
  );
}

const mapStateToProps = (state, ownProps) => {
  const property = find(state.properties.properties, {'_id': ownProps.match.params.id});

  return {
    user: state.authentication.user,
    property,
    activities: state.activities.activities,
  }
}


export default connect(mapStateToProps, actions)(Property);
