import React from 'react';
import { connect } from 'react-redux';
import { BackButton } from '../../parts/Button';
import { find, filter } from 'lodash';
import * as actions from '../../../actions';

class CreateUserProperty extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      name: '',
      addressStreet: '',
      addressPostalCode: '',
      addressCity: '',
    }
  }

  componentDidMount(){
    this.props.fetchUserProperties(this.props.match.params.userId);
    this.props.fetchUser(this.props.match.params.userId);
  }

  componentDidUpdate(prevProps){
    if(prevProps.userProperties.length < this.props.userProperties.length){
      this.props.history.push(`/management/users/${this.props.match.params.userId}/properties/${this.props.userProperties[this.props.userProperties.length - 1]._id}`);
    }
  }
  _handleSubmit = event => {
    event.preventDefault();

    // TODO: remove hard-coded icon once backend is fixed

    this.props.createUserProperty({
      userId: this.props.user._id,
      property: {
        ...this.state,
        icon: 'http://portal.hans-hausmeister.de/house.png'
      }
    });
  }

  _handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render(){
    console.log(this.props.match.params.userId);
    return (
      <div>
        <BackButton />

        <h2>
          Neues Objekt anlegen
        </h2>
        <form className="login" onSubmit={e => this._handleSubmit(e)}>
          <input type="text" name="name" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Bezeichnung" value={this.state.name}/>
          <br />
          <input type="text" name="addressStreet" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Straße" value={this.state.addressStreet}/>
          <br />
          <input type="text" name="addressPostalCode" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Postleitzahl" value={this.state.addressPostalCode}/>
          <br />
          <input type="text" name="addressCity" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Stadt" value={this.state.addressCity}/>
          <br />

          {this.props.error &&
            <div className="error-message">{this.props.error}</div>
          }
          <br />
          {this.props.user &&
            <select disabled required defaultValue={this.props.user._id}>
              <option value={this.props.user._id}>{this.props.user.firstName} {this.props.user.lastName}</option>
            </select>
          }
          <br />
          <br />
          <button type="submit" className="button button--small button--primary button--black">Objekt anlegen</button>
        </form>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { properties, loading, error } = state.admin.properties;
  const user = find(state.admin.users.users, {_id: ownProps.match.params.userId})
  let userProperties = [];

  if(properties){
    userProperties = filter(properties, {owner: ownProps.match.params.userId});
  }

  return {
    currentUser: state.authentication.user,
    user,
    userProperties,
    loading,
    error
  }
}


export default connect(mapStateToProps, actions)(CreateUserProperty);
