import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import classNames from 'classnames';
import { Logo } from '../../parts/Svg';

import Welcome from './Welcome';
import Details from './Details';

import * as actions from '../../../actions';


const Onboarding = props => {
  useEffect(() => {
    props.fetchOnboardingDetails({
      email: props.match.params.email,
      resetToken: props.match.params.token
    });
  }, []);

  const { location, match } = props;

  return (
    <div className={classNames('page page--onboarding')}>

        <Switch location={location}>
          <Route exact path={`${match.path}/`} component={Welcome} />
          <Route exact path={`${match.path}/details`} component={Details} />
        </Switch>

      <Logo className="logo" color="#000000"/>
      <div className="cover" />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    // error: state.authentication.error
  };
}

export default connect(mapStateToProps, actions)(Onboarding);
