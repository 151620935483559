const propertiesState = {
  loading: false,
  error: false,
  user: false
};

export default (state = propertiesState, action) => {
  switch(action.type){
    // FETCH PROPERTIES
    case 'FETCH_ONBOARDING_DETAILS_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_ONBOARDING_DETAILS_SUCCESS':
      return {
        ...state,
        user: action.user,
        loading: false
      };
    case 'FETCH_ONBOARDING_DETAILS_ERROR':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
