import axios from 'axios';
import { tokenHeader } from '../authentication';

export function fetchPropertyActivities(propertyId){
	return dispatch => {
		dispatch({type: 'FETCH_PROPERTY_ACTIVITIES_REQUEST'});
		axios.get(`${process.env.REACT_APP_API_BASE}/admin/properties/${propertyId}/activities?sortBy=createdAt:desc`, tokenHeader())
		.then(response => {
      dispatch({
				type: 'FETCH_PROPERTY_ACTIVITIES_SUCCESS',
				propertyId,
				activities: response.data
			});
		})
		.catch(error => {
			dispatch({type: 'FETCH_PROPERTY_ACTIVITIES_ERROR'});
		});
	}
}

export function createPropertyActivity(data){
	return (dispatch) => {
		dispatch({type: 'CREATE_PROPERTY_ACTIVITY_REQUEST'});
    axios.post(`${process.env.REACT_APP_API_BASE}/admin/properties/${data.propertyId}/activities`, data.activity, tokenHeader())
    .then(response => {
			dispatch({
				type: 'CREATE_PROPERTY_ACTIVITY_SUCCESS',
				activity: response.data
			});
    })
    .catch((error) =>{
			dispatch({
				type: 'CREATE_PROPERTY_ACTIVITY_ERROR',
				error
			});
    });
  }
}
