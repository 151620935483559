import axios from 'axios';

export function tokenHeader(){
	const token = localStorage.getItem('hansAuthToken');
	return { headers: { Authorization: `Bearer ${token}` } };
}

export function signInUser(credentials){
  return (dispatch) => {
    axios.post(`${process.env.REACT_APP_API_BASE}/users/login`, credentials)
    .then(response => {
      console.log(response);
      localStorage.setItem('hansAuthToken',response.data.token);
      dispatch(fetchLoggedInUserData());
      dispatch({ type: 'AUTH_USER' });
    })
    .catch((error) =>{
      dispatch(authError('Diese Zugangsdaten sind falsch.'))
    });
  }
}

export function signoutUser(msg = 'You are successfully logged out.'){
	localStorage.removeItem('hansAuthToken');
	return { type: 'UNAUTH_USER', payload: msg};
}

export function fetchLoggedInUserData(){
	return dispatch => {
		axios.get(`${process.env.REACT_APP_API_BASE}/users/me`, tokenHeader())
		.then(response => {
      dispatch({ type: 'FETCH_LOGGED_IN_USER', payload: response.data });
		})
		.catch(error => { dispatch(signoutUser('Please log in again.')); });
	}
}

export function sendPasswordResetMail(email){
	return dispatch => {
		axios.post(`${process.env.REACT_APP_API_BASE}/users/password/reset`, {email})
		.then(response => {
			dispatch({
				type: 'SEND_PASSWORD_RESET_MAIL_SUCCESS'
			});
		})
		.catch(error => {
			dispatch({
				type: 'SEND_PASSWORD_RESET_MAIL_ERROR',
				error: error.response
			});
		});
	}
}

export function resetPassword(data){
	return dispatch => {
		axios.patch(`${process.env.REACT_APP_API_BASE}/users/password/update`, data)
		.then(response => {
			dispatch({
				type: 'RESET_PASSWORD_SUCCESS',
			});
		})
		.catch(error  => {
			dispatch({
				type: 'RESET_PASSWORD_ERROR',
				error: error.response
			});
		});
	}
}

export function authError(error){
	return {
		type: 'AUTH_ERROR',
		payload: error
	};
}
