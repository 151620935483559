import React from 'react';
import Button from '../parts/Button';
import { Success } from '../parts/Svg';

const SuccessModal = props => {
  const close = () => {
    props.onClose('param', 'param2', 'param3');
  }

  return <div className="modal modal--success">
    <div className="modal__box">
      <div className="modal__box__body">
        <Success className="icon" />
        <span className="title">{props.title || ''}</span>
        <span style={{color: '#888'}}>{props.body || ''}</span>
      </div>
      <Button className="modal__box__footer" type="primary" color="grey" onClick={() => close()}>Weiter</Button>
    </div>
  </div>;
}

export default SuccessModal;
