import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import Button from '../../parts/Button';
import moment from 'moment';
import { withPopups } from "react-popup-manager";
import SendNotification from '../../modals/SendNotification';

import 'moment/locale/de';

moment.locale('de');

const Users = props => {
  useEffect(() => {
    props.fetchUsers();
  }, []);

  const openModal = user => {
    props.popupManager.open(SendNotification, {
      title: 'Benachrichtigung an Kunden senden',
      data:{
        user
      },
      onClose: (...params) => console.log('modal has closed with:', ...params)});
  }

  if(!props.users){
    return false;
  }

  const mappedUsers = props.users.map(user => ({
    'Anrede': user.salutation,
    'Vorname': user.firstName,
    'Nachname': user.lastName,
    'E-Mail-Adresse': user.email,
    'Status': user.status,
    'angelegt': moment(user.createdAt).fromNow(),
  }));

  return (
    <div>
    <Button type="primary" size="small" to={`/management/users/new`}>
      Neuen Kunden anlegen
    </Button>
    <br />
    <br />
    {mappedUsers.length &&
      <table cellSpacing="0">
        <tbody>
          <tr>
            {Object.keys(mappedUsers[0]).map((prop, i) =>
              <th key={i}>{prop}</th>
            )}
          </tr>
          {mappedUsers.map((user, i) =>
            <tr key={i}>
            {Object.keys(user).map((prop, i) =>
              <td key={i}>{user[prop]}</td>
            )}
            <td>
              <Button type="primary" size="small" to={`/management/users/${props.users[i]._id}`}>
                →
              </Button>
            </td>
            <td className="actions">
              <Button type="primary" size="small" onClick={() => openModal(user)} className="actions">
                <span />
                <span />
                <span />
              </Button>
            </td>
            </tr>
          )}
        </tbody>
      </table>
    }
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.admin.users.users,
  }
}

export default connect(mapStateToProps, actions)(withPopups()(Users));
