import React from 'react';
import Header from './Header';
import { NavLink } from 'react-router-dom';

export const SubMenu = props => props.items && (
  <div className="sub-menu">
    <div className="inner">
      <ul>
        {props.items.map((item, i) => (
          <li key={i}>
            <NavLink exact={item.exact} activeClassName="active" to={item.to}>
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default props => {
  return <div className="page page--app">
    <Header />
    {props.submenu &&
      <SubMenu items={props.submenu} />
    }
    <div className="page__inner">
      <div className="inner">
        {props.title &&
          <div className="page__title">{props.title}</div>
        }
        {props.children}
      </div>
    </div>
  </div>
}
