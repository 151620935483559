import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';

const PropertyList = props => {
  const handlePropertyDelete = (e, property) => {
    e.preventDefault();

    if(window.confirm('Objekt sicher löschen?')){
      if(props.currentUser.role === 'admin'){
        props.deleteUserProperty(property);
      }else{
        props.deleteProperty(property);
      }
    }
  }

  return (
    <React.Fragment>
      <div>
        <ul className="properties">
          {props.properties && props.properties.map(property => {
            const link = props.currentUser.role === 'admin' ? `/management/users/${property.owner}/properties/${property._id}` : `/properties/${property._id}`;

            return (
              <li key={property._id}>
                <Link to={link} className="property">
                  <div className="property__delete material-icons" onClick={(e) => handlePropertyDelete(e, property)}>close</div>
                  <img className="property__image" src={`/house.png`} alt="property"/>
                  <div className="property__title">{property.name}</div>
                  <div className="property__address">
                    {property.addressStreet} <br />
                    {property.addressPostalCode} <br />
                    {property.addressCity}
                  </div>
                </Link>
              </li>
            )
          })}
          {props.enableAddNew &&
            <li>
              <Link to={{ pathname: props.currentUser.role === 'admin' && props.user ? `/management/users/${props.user._id}/properties/new` : `/properties/new`, query:{ user: props.user} }} className="property property--placeholder">
                <div className="property__title">Neues Objekt anlegen</div>
              </Link>
            </li>
          }
        </ul>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.authentication.user,
  }
}

export default connect(mapStateToProps, actions)(PropertyList);
