import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

const Button = props => {
  const ComponentName = props.to ? Link : 'button';
  const type = props.type ? props.type : 'primary';
  const color = props.color ? props.color : 'black';

  return (
    <ComponentName
      {...props}
      type={!props.to ? 'submit' : undefined}
      className={classNames('button',
        `button--${type}`,
        `button--${color}`,
        props.size && `button--${props.size}`,
        props.className
      )}
      disabled={props.disabled}>
      {props.children}
    </ComponentName>
  )
}

export const BackButton = withRouter(props => {
  return (
    <Button type="primary" color="grey" size="small" className="back" onClick={() => props.history.goBack()}>zurück</Button>
  )
})


export default Button;
