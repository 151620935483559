const authenticationState = {
  user: false,
  authenticated: false,
  passwordReset: false,
  error: false
};

export default (state = authenticationState, action) => {
  switch(action.type){
    case 'AUTH_USER':
      return {
        ...state,
        authenticated: true,
      };
    case 'UNAUTH_USER':
      return {
        ...state,
        authenticated: false,
        user: false
      };
    case 'FETCH_LOGGED_IN_USER':
      return {
        ...state,
        user: action.payload
      };
    case 'AUTH_ERROR':
      return {
        ...state,
        error: action.payload
      }
    case 'SEND_PASSWORD_RESET_MAIL_ERROR':
    case 'RESET_PASSWORD_ERROR':
      // TODO: Error unify errors

      return {
        ...state,
        passwordReset: false,
        error: action.error.data.message
      }
    case 'SEND_PASSWORD_RESET_MAIL_SUCCESS':
      return {
        ...state,
        error: false,
        passwordReset: 'requested'
      }
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        error: false,
        passwordReset: 'reset'
      }

    // SIGNUP USER
    case 'SIGNUP_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        loading: false
      };
    case 'SIGNUP_ERROR':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
