import React from 'react';
import PlaceholderPage from '../layouts/PlaceholderPage';
import Button from '../parts/Button';

const Billing = props => {
  return (
    <PlaceholderPage title="Deine Rechnungen" icon={'/illustrations/18- receipt.png'}>
      Deine Rechnungen bekommst du zu deinem jeweiligen Auftrag per E-Mail oder Post von uns nach Auftragsabschluss. Solltest du eine Rechnung erneut brauchen, schreib’ uns gern eine E-Mail an invoice@hans-hausmeister.de.
      <br />
      <br />
      <Button to="mailto:invoice@hans-hausmeister.de">E-Mail schreiben</Button>
    </PlaceholderPage>
  );
}

export default (Billing);
