import { merge } from '../../helpers.js';

const activitiesState = {
  loading: false,
  activities: [],
  success: false,
  error: false
};

export default (state = activitiesState, action) => {
  switch(action.type){
    case 'FETCH_PROPERTY_ACTIVITIES_REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'FETCH_PROPERTY_ACTIVITIES_SUCCESS':
      const activities = merge(state.activities, action.activities);

      return {
        ...state,
        activities,
        loading: false,
        error: false,
      };
    case 'FETCH_PROPERTY_ACTIVITIES_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case 'CREATE_PROPERTY_ACTIVITY_REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case 'CREATE_PROPERTY_ACTIVITY_SUCCESS':
      return {
        ...state,
        activities: [
          ...state.activities,
          action.activity
        ],
        loading: false,
        error: false,
        success: true,
      };
    case 'CREATE_PROPERTY_ACTIVYES_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };
    default:
      return state;
  }
}
