import { combineReducers } from 'redux'
import authentication from './authentication';
import properties from './properties';
import activities from './activities';
import onboarding from './onboarding';

import users from './admin/users';
import userActivities from './admin/activities';
import userProperties from './admin/properties';
import notifications from './admin/notifications';

export default combineReducers({
  authentication,
  properties,
  activities,
  onboarding,
  admin: combineReducers({
    properties: userProperties,
    activities: userActivities,
    users,
    notifications,
  }),
})
