import axios from 'axios';
import { tokenHeader } from './authentication';

export function fetchActivities(){
	return dispatch => {
		dispatch({type: 'FETCH_ACTIVITIES_REQUEST'});
		axios.get(`${process.env.REACT_APP_API_BASE}/activities`, tokenHeader())
		.then(response => {
      dispatch({
				type: 'FETCH_ACTIVITIES_SUCCESS',
				activities: response.data
			});
		})
		.catch(error => {
			dispatch({type: 'FETCH_ACTIVITIES_ERROR'});
		});
	}
}
