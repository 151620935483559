import axios from 'axios';

export function fetchOnboardingDetails(data){
	return dispatch => {
		dispatch({type: 'FETCH_ONBOARDING_DETAILS_REQUEST'});
		axios.get(`${process.env.REACT_APP_API_BASE}/users/onboard`, {params:data})
		.then(response => {
      dispatch({
				type: 'FETCH_ONBOARDING_DETAILS_SUCCESS',
				user: response.data
			});
		})
		.catch(error => {
			dispatch({type: 'FETCH_ONBOARDING_DETAILS_ERROR'});
		});
	}
}
