import React from 'react';
import { connect } from 'react-redux';
import AuthenticationPage from '../layouts/AuthenticationPage';
import Input from '../parts/Input';
import Button from '../parts/Button';

import * as actions from '../../actions';

class ResetPassword extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      password1: '',
      password2: '',
    }
  }

  componentDidUpdate(prevProps){
    if(!prevProps.error && this.props.error){

    }
  }

  _handleSubmit = event => {
    event.preventDefault();

    this.props.resetPassword({
      password: this.state.password1,
      resetToken: this.props.match.params.token,
      email: this.props.match.params.email
    });
  }

  _handleChange = event => {
    console.log(event);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render(){
    console.log(this.props);
    return (
      <AuthenticationPage>
        <form className="login" onSubmit={e => this._handleSubmit(e)}>
          <p>Hinweis: Ein sicheres Passwort sollte mehr als 6 Zeichen besitzen und aus Buchstaben, Zahlen und Sonderzeichen bestehen.</p>
          <br />
          <Input type="password" name="password1" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Dein neues Passwort" value={this.state.password1}/>
          <br />
          <Input type="password" name="password2" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Bestätige dein Passwort" value={this.state.password2}/>
          {this.props.error &&
            <div className="error-message">{this.props.error}</div>
          }
          <br />
          <Button type="primary" disabled={this.state.password1 === '' || (this.state.password1 !== this.state.password2)}>Passwort festlegen</Button>
        </form>
      </AuthenticationPage>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.authentication.error
  };
}

export default connect(mapStateToProps, actions)(ResetPassword);
