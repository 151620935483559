import axios from 'axios';
import { tokenHeader } from '../authentication';

export function sendEmail(email){
	return (dispatch) => {
		dispatch({type: 'SEND_EMAIL_REQUEST'});
    axios.post(`${process.env.REACT_APP_API_BASE}/admin/email`, email, tokenHeader())
    .then(response => {
      console.log(response);
			dispatch({type: 'SEND_EMAIL_SUCCESS'});
    })
    .catch((error) =>{
			dispatch({type: 'SEND_EMAIL_ERROR'});
    });
  }
}
