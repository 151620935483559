import React from 'react';
import Header from './Header';

export default props => {
  console.log(props.icon);
  return <div className="page page--placeholder">
    <Header />
    <div className="page__inner">
      {props.icon &&
        <div className="page__icon">
          <img src={props.icon} alt={props.title}/>
        </div>
      }
      {props.title &&
        <div className="page__title">{props.title}</div>
      }
      <div className="page__content">
        {props.children}
      </div>
    </div>
  </div>
}
