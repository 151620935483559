import React from 'react';
import { connect } from 'react-redux';

import Button from '../../parts/Button';

import * as actions from '../../../actions';

class Welcome extends React.Component{
  render(){
    const { firstName } = this.props.user;

    return (
      <div className="box">
        <div className="inner">
          <img className="icon" src={'/illustrations/welcome.png'} alt=""/>
          {firstName ?
            <h1>Hallo {firstName}, willkommen bei Hans</h1>
            :
            <h1>&nbsp;</h1>
          }
          <p>Vervollständige deine Anmeldung in nur 2 Minuten und verwalte alle deine Immobilien auf einen Blick</p>
          <br />
          <br />
          <Button to={`${this.props.location.pathname}/details`} color="green">Los geht’s</Button>
          <br />
          <br />
        </div>
      </div>
    );
  }
};


const mapStateToProps = (state, ownProps) => {
  return {
    user: state.onboarding.user
  }
}


export default connect(mapStateToProps, actions)(Welcome);
